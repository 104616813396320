/**
 * INSPINIA - Responsive Admin Theme
 * 2.4
 *
 * Custom scripts
 */
window.paceOptions = {
  document: true, // disabled
  eventLag: true,
  restartOnPushState: true,
  restartOnRequestAfter: true,
  ajax: { trackMethods: ['POST', 'GET', 'PUT', 'DELETE', 'OPTIONS'] },
};

$(function () {
  // Minimalize menu when screen is less than 768px.
  $(window).on("load resize", function () {
    if ($(this).width() < 769) {
      $('body').addClass('body-small')
    } else {
      $('body').removeClass('body-small')
    }
  });

  // Environment header setup.
  const curURL = window.location.href;
  let environmentName = null;
  if (curURL.startsWith("https://devv")) {
    environmentName = "Development";
  } else if (curURL.startsWith("https://testt")) {
    environmentName = "Test";
  } else if (curURL.startsWith("https://stagingg")) {
    environmentName = "Staging";
  } else if (curURL.startsWith("https://web-migration")) {
    environmentName = "Migration";
  }
  if (environmentName) {
    document.getElementById("showEnvironment").style.display = "";
    document.getElementById("environmentName").innerHTML = `Command Center ${environmentName} Environment`;
  }
});
